import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import {
    BrowserRouter, Route, Routes,
} from 'react-router-dom';

import { CustomLinksPage, Homepage, NotFoundPage } from '../pages';
import themeSpec from '../themes';
import Header from './header/Header';
import WorkInProgress from './WorkInProgress';
/**
 * SPA router component.
 *
 * @returns {React.ReactElement} component
 */
function Router() {
    const theme = createTheme(themeSpec);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Box>
                    <Header />
                </Box>

                <Box>
                    <Routes>
                        <Route path="" element={<Homepage />} />
                        <Route path="/links" element={<CustomLinksPage />} />
                        <Route path="*" element={<WorkInProgress />} />
                    </Routes>
                </Box>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default Router;
