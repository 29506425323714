import { Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import React from 'react';

/**
 * HeaderNavLink component.
 * Provides the LINK routing to the endpoint.
 *
 * @component
 * @param {object} props object.
 * @param {string} props.endpoint endpoint of the website.
 * @param {React.ReactElement} props.children Returns any children e.g <HeaderNavButton>.
 * @returns {React.ReactElement} component
 */
function HeaderNavLink({ endpoint, children }) {
    const theme = useTheme();

    return (
        <Link
            href={endpoint}
            color={theme.headerBackgroundColor}
            underline="none"
        >
            {children}
        </Link>
    );
}

HeaderNavLink.propTypes = {
    endpoint: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

HeaderNavLink.defaultProps = {
    endpoint: null,
};

export default HeaderNavLink;
