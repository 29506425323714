import { Box } from '@mui/material';
import React from 'react';

import NCHLogo from '../../images/nchlogo.png';

/**
 * HeaderSkyLogo component.
 *
 * @component
 * @returns {React.ReactElement} component.
 */
function HeaderLogo() {
    return (
        <Box
            component="img"
            sx={{
                height: 200,
                width: 200,
                maxHeight: { xs: 60 },
                maxWidth: { xs: 70 },
            }}
            alt="logo"
            src={NCHLogo}
            mr={1}
        />
    );
}

export default HeaderLogo;
