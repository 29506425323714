import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ContactsIcon from '@mui/icons-material/Contacts';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LaunchIcon from '@mui/icons-material/Launch';
import {
    AppBar, Box,
    Container, Toolbar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { HeaderLogo, HeaderNavButton, HeaderNavLink } from '.';

/**
 * Header (top nav bar) component.
 *
 * @component
 * @returns {React.ReactElement} component
 */
function Header() {
    const theme = useTheme();

    return (
        <AppBar
            position="static"
            elevation={0}
            sx={{ background: theme.headerBackgroundColor, borderBottom: '1px solid gray' }}
        >
            <Container maxWidth="false">
                <Toolbar disableGutters>
                    <HeaderLogo />

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>

                        <HeaderNavLink>
                            <HeaderNavButton pagename="Home" endpoint="" icon={<HomeIcon />} />
                        </HeaderNavLink>

                        <HeaderNavLink>
                            <HeaderNavButton
                                pagename="About Me"
                                endpoint="about_me"
                                icon={<InfoIcon />}
                            />
                        </HeaderNavLink>

                        <HeaderNavLink>
                            <HeaderNavButton
                                pagename="Project Portfolio"
                                endpoint="portfolio"
                                icon={<AccountTreeIcon />}
                            />
                        </HeaderNavLink>

                        <HeaderNavLink>
                            <HeaderNavButton
                                pagename="Useful Links"
                                endpoint="links"
                                icon={<LaunchIcon />}
                            />
                        </HeaderNavLink>

                        <HeaderNavLink>
                            <HeaderNavButton
                                pagename="Contact"
                                endpoint="contact"
                                icon={<ContactsIcon />}
                            />
                        </HeaderNavLink>

                        <Box sx={{ flexGrow: 1 }} />
                        <HeaderNavLink>
                            <HeaderNavButton pagename="Help" endpoint="help" icon={<HelpCenterIcon />} />
                        </HeaderNavLink>
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;
