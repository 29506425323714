import { Box, Container, Grid } from '@mui/material';
import React from 'react';

import maintenance from '../images/maintenance.png';

/**
 * WorkInProgress Component displays an image
 *
 * @returns {React.Component} component
 */
function WorkInProgress() {
    return (
        <Container maxWidth="xl">
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ pb: 2, pt: 15 }}
            >

                <Box
                    component="img"
                    src={maintenance}
                />
            </Grid>
        </Container>
    );
}

export default WorkInProgress;
