const lightTheme = {
    faintSuccess: '#f8fff8',
    faintWarning: '#fffff8',
    faintError: '#fff8f8',
    strongerSuccess: '#e8ffe8',
    strongerWarning: '#ffffe8',
    strongerError: '#ffe8e8',
    faint: '#e8e8e8',
    headerBackgroundColor: '#ffffff',
    headerMenuButtons: {
        my: 2,
        color: 'black',
        border: '1px solid transparent',
        ':hover': {
            bgcolor: 'black',
            color: 'white',
            border: '1px solid #ffffff',
        },
    },
    headerMenuDropdownButtons: {
        border: '1px solid transparent',
        ':hover': {
            bgcolor: 'black',
            color: 'white',
            border: '1px solid #ffffff',
        },
    },
    breakpoints: {
        values: {
            // Add in default as overriding with custom
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            // Custom Breakpoints
            laptop: 1800,
            desktop: 2000,
        },
    },
};

export default lightTheme;
