import {
    Box, Card, CardContent, CardMedia,
    Container, Grid, Typography,
} from '@mui/material';
import React from 'react';

import dockerLogo from '../images/docker_logo.png';
import flaskLogo from '../images/flask_logo.png';
import galaxy from '../images/galaxy.jpg';
import jsLogo from '../images/js_logo.png';
import linuxLogo from '../images/linux_logo.png';
import mongodbLogo from '../images/mongodb_logo.png';
import mysqlLogo from '../images/mysql_logo.png';
import nodeLogo from '../images/nodejs_logo.png';
import pythonLogo from '../images/python_logo.png';
import ReactLogo from '../images/reactjs_logo.png';
import skyLogo from '../images/sky_logo.png';

/**
 * Homepage page component.
 *
 * @component
 * @returns {React.ReactElement} component
 */
function Homepage() {
    const cardObjects = [{
        title: 'Python',
        image: pythonLogo,
        text: '',
    },
    {
        title: 'ReactJS',
        image: ReactLogo,
        text: '',
    },
    {
        title: 'Javascript',
        image: jsLogo,
        text: '',
    },
    {
        title: 'NodeJS',
        image: nodeLogo,
        text: '',
    },
    {
        title: 'MySQL',
        image: mysqlLogo,
        text: '',
    },
    {
        title: 'MongoDB',
        image: mongodbLogo,
        text: '',
    },
    {
        title: 'Linux',
        image: linuxLogo,
        text: '',
    },
    {
        title: 'Docker',
        image: dockerLogo,
        text: '',
    },
    {
        title: 'Flask Framework',
        image: flaskLogo,
        text: '',
    },
    ];

    return (
        <>
            <div
                className="container"
                style={{
                    backgroundImage: `url(${galaxy})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: '100%',
                    height: '1000px',
                }}
            >
                <Container>

                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ pb: 2 }}
                    >
                        <Grid item laptop={10} desktop={8} key="item_grid_overview">
                            <Card
                                elevation={22}
                                sx={{ mt: 50, backgroundColor: 'transparent', border: '1px dotted white' }}
                            >

                                <CardContent sx={{ color: '#ffffff' }}>
                                    <Typography
                                        variant="h4"
                                        component="div"
                                        sx={{ fontWeight: 'bold' }}
                                    >
                                        Nayam Chowdhury
                                    </Typography>

                                    <Typography
                                        variant="h6"
                                        component="div"
                                        sx={{ display: 'flex' }}
                                    >
                                        Software Developer @

                                        <Box
                                            component="img"
                                            alt="logo"
                                            src={skyLogo}
                                            mr={1}
                                            sx={{
                                                pl: 1,
                                                maxHeight: { xs: 30 },
                                                maxWidth: { xs: 55 },
                                            }}
                                        />

                                    </Typography>

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <Container maxWidth="xl">
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pb: 2, pt: 15 }}
                >

                    {cardObjects.map((card) => (
                        <Grid item laptop={4} desktop={4} key={card.title} sx={{ pl: 10, pb: 6 }}>
                            <Card
                                elevation={12}
                                sx={{
                                    maxWidth: 300,
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    sx={{ maxHeight: 150, objectFit: 'contain' }}
                                    image={card.image}
                                    title={card.title}
                                />
                                <CardContent>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        sx={{ ml: '1%', fontWeight: 'bold' }}
                                    >
                                        {card.title}
                                    </Typography>

                                    {card.text}

                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </>
    );
}

export default Homepage;
