import {
    Box,
    Container, Typography,
} from '@mui/material';
import React from 'react';

import PageNotFoundImage from '../images/404_page_not_found.png';

/**
 * Not found page component.
 *
 * @component
 * @returns {React.ReactElement} component
 */
function NotFoundPage() {
    return (
        <Container align="center" sx={{ my: 5 }}>
            <Typography variant="h2">
                Lost?
            </Typography>
            <Typography>
                Page Not Found - Please Turn Around.
            </Typography>
            <Box
                component="img"
                sx={{
                    height: '100%',
                    width: '100%',
                }}
                src={PageNotFoundImage}
            />
        </Container>
    );
}

export default NotFoundPage;
