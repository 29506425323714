import React from 'react';

/**
 *
 */
function CustomLinksPage() {
    return (
        <div>CustomLinksPage</div>
    );
}

export default CustomLinksPage;
