import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropTypes } from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * HeaderNavButton component.
 * This is the clickable buttons that are standalone
 * and direct you to another page.
 *
 * @component
 * @param {object} props object.
 * @param {string} props.pagename Name of the Page.
 * @param {string} props.endpoint endpoint of the website.
 * @param {React.ReactElement} props.icon Icon Component.
 * @returns {React.ReactElement} component
 */
function HeaderNavButton({ pagename, endpoint, icon }) {
    const navigate = useNavigate();

    function handlePageClick(e, pageURL) {
        e.preventDefault();
        navigate(pageURL.toLowerCase());
    }

    const theme = useTheme();

    return (
        <Button
            onClick={(e) => { handlePageClick(e, endpoint); }}
            sx={theme.headerMenuButtons}
        >
            {icon}
            <Typography sx={{ ml: 1 }}>{pagename}</Typography>
        </Button>
    );
}

HeaderNavButton.propTypes = {
    pagename: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
};

export default HeaderNavButton;
